<template>
    <div>
        <form-item-label :data="data"/>
        <input class="form-control" type="email" :placeholder="data.placeholderVal" v-model="inputValue" :disabled="isDisabled" />
        <div class="alert alert-danger" v-if="validationError"> {{ validationError }} </div>
    </div>
</template>
<script>
import { defineComponent, onMounted, ref, watch } from 'vue'
import * as yup from 'yup'
import { useApplyStore } from '@/store/apply'

export default defineComponent({
  name: 'FormItemEmail',
  props: {
    data: {},
    disabled: null
  },
  data () { return {} },
  disabled () { return false },
  setup (props) {
    const applyStore = useApplyStore()
    const validationError = ref(null)
    const inputValue = ref(null)
    const isDisabled = ref(false)

    const schema = yup.string().required().email()

    watch(inputValue, async () => {
      const isValid = await schema.isValid(inputValue.value)
      if (isValid) {
        applyStore.setAnswer(props.data.id, inputValue.value)
        validationError.value = false
      } else {
        validationError.value = 'Eposta adresi hatalı!'
      }
    })

    onMounted(() => {
      if (props.data.answer != null) {
        inputValue.value = props.data.answer.formItemReply
      }

      if (props.disabled != null) isDisabled.value = props.disabled
    }
    )

    return {
      applyStore,
      inputValue,
      isDisabled,
      validationError
    }
  },
  components: {

  },
  methods: {

  }
})
</script>
