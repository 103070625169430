<template>
    <div>
        <form-item-label :data="data"/>
        <textarea class="form-control" @change="onChange($event)" :placeholder="data.placeholderVal" v-model="inputValue" :disabled="isDisabled" :maxlength="maxLength" />
    </div>
</template>
<script>
import { defineComponent, onMounted, ref } from 'vue'
import { useApplyStore } from '@/store/apply'

export default defineComponent({
  name: 'FormItemTextarea',
  props: {
    data: {},
    disabled: null
  },
  data () { return {} },
  disabled () { return false },
  setup (props) {
    const applyStore = useApplyStore()
    const inputValue = ref(null)
    const isDisabled = ref(false)
    const maxLength = ref(-1)

    const onChange = (event) => {
      const value = event.target.value
      applyStore.setAnswer(props.data.id, value)
    }

    onMounted(() => {
      if (props.data.answer != null) inputValue.value = props.data.answer.formItemReply
      if (props.data.maxlength === 1) maxLength.value = props.data.maxlengthVal
      if (props.disabled != null) isDisabled.value = props.disabled
    }
    )

    return {
      applyStore,
      inputValue,
      isDisabled,
      onChange,
      maxLength
    }
  },
  components: {

  },
  methods: {

  }
})
</script>
