import { acceptHMRUpdate, defineStore } from 'pinia'
import AuthService from '../services/auth.service'
import { useRoute, useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'

export const useAuthStore = defineStore({
  id: 'authStore',
  state: () => ({
    user: localStorage.getItem('user'),
    isLoggedIn: !(localStorage.getItem('token') === undefined || localStorage.getItem('token') === null),
    token: localStorage.getItem('token'),
    refreshToken: localStorage.getItem('refreshToken'),
    route: useRoute(),
    router: useRouter(),
    toast: useToast()
  }),
  setup () {},
  actions: {
    checkIsLoggedIn () {
      if (this.isLoggedIn) return true
      else this.router.push('/login')

      return false
    },
    getToken () {
      return this.token
    },
    renewToken () {
      this.token = null
      return AuthService.renewToken(this.refreshToken).then(
        response => {
          if (response.data !== undefined) {
            this.isLoggedIn = true
            this.token = response.data.token
            this.refreshToken = response.data.refreshToken

            localStorage.setItem('token', response.data.token)
            localStorage.setItem('refreshToken', response.data.refreshToken)
          }
          return response
        }
      )
    },
    login (formData,iframeToken) {
      return AuthService.login(formData,iframeToken).then(
        response => {
          if (response.status === 200) {
            // eslint-disable-next-line no-empty
            if (response.data.redirect && response.data.redirect !== []) { } else {
              this.user = formData.username

              this.isLoggedIn = true
              this.token = response.data.token
              this.refreshToken = response.data.refreshToken

              localStorage.setItem('user', formData.username)
              localStorage.setItem('token', this.token)
              localStorage.setItem('refreshToken', this.refreshToken)
            }
          }
          return response
        }
      )
    },
    logout () {
      this.user = null
      this.isLoggedIn = false
      this.token = null
      this.refreshToken = null
      localStorage.removeItem('user')
      localStorage.removeItem('token')
      localStorage.removeItem('refreshToken')
      this.router.push('/login')
    },
    signUp (formData) {
      formData.invitation_token = ''
      return AuthService.signUp(formData)
    },
    activate () {
      // Aktivasyon işlemi için
      const token = this.route.params.token
      return AuthService.activate(token)
    },
    activationSignUp (formData) {
      // Kayıt+Aktivasyon işlemi için
      const token = this.route.params.token
      formData.invitation_token = token
      formData.email = ''
      return AuthService.signUp(formData)
    },
    passwordUpdateabilityCheck (formData) {
      // Şifre güncellenebilir mi
      return AuthService.passwordUpdateabilityCheck(formData)
    },
    resetPassword (formData) {
      // Şifre yenileme isteği
      return AuthService.resetPassword(formData)
    },
    renewPassword (formData) {
      // Token ile şifre yenileme
      return AuthService.renewPassword(formData)
    },
    profileRenewPassword (formData) {
      // Token ile şifre yenileme
      return AuthService.profileRenewPassword(formData)
    },
    changePassword (formData) {
      return AuthService.changePassword(formData)
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}
