import axios from 'axios'
import { acceptHMRUpdate, defineStore } from 'pinia'

export const useConfigStore = defineStore({
  id: 'configStore',
  state: () => ({
    isReady: false,
    data: {

    }
  }),
  setup () {

  },
  actions: {
    downloadBasePath () {
      return this.data.VUE_APP_API_BASE_URL + '/api/v1/file/download/'
    },
    imageBasePath () {
      return this.data.VUE_APP_API_BASE_URL + '/api/v1/file/show/'
    },
    load () {
      return fetch('/config.json')
        .then(r => r.json())
        .then(data => {
          this.data = data
          axios.defaults.baseURL = data.VUE_APP_API_BASE_URL + '/api'
          this.isReady = true
          return data
        })
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useConfigStore, import.meta.hot))
}
