import { BaseService } from './BaseService'
const COMPANY_INFO_PATH = '/v1/company/info'

class CompanyService extends BaseService {
  getCompanyInfo () {
    return this.get(COMPANY_INFO_PATH, null, false)
  }
}

export default new CompanyService()
