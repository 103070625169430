<template>
    <div>
        <form-item-label :data="data"/>
        <star-rating  :star-size="20" :max-rating="data.maxlengthVal" v-model:rating="rating" :read-only="isDisabled"></star-rating>
    </div>
</template>
<script>
import { defineComponent, onMounted, ref, watch } from 'vue'
import { useApplyStore } from '@/store/apply'
import StarRating from 'vue-star-rating'

export default defineComponent({
  name: 'FormItemStarRating',
  props: {
    data: {},
    disabled: null
  },
  data () { return {} },
  disabled () { return false },
  setup (props) {
    const applyStore = useApplyStore()
    const rating = ref(null)
    const isDisabled = ref(false)

    watch(rating, (currentValue, oldValue) => {
      if (oldValue !== currentValue) {
        applyStore.setAnswer(props.data.id, currentValue)
      }
    })

    onMounted(() => {
      if (props.data.answer != null) {
        rating.value = props.data.answer.formItemReply
      }

      if (props.disabled != null) isDisabled.value = props.disabled
    }
    )

    return {
      applyStore,
      isDisabled,
      rating
    }
  },
  components: {
    StarRating
  },
  methods: {

  }
})
</script>
