<template>
  <div>
    <form-item-label :data="data"/>
    <select class="form-select" @change="onChange($event)" :disabled="isDisabled">
      <option v-for="city in data.city_list" :key="city.id" :value="city.id" :selected="city.id==selectedId">
        {{ city.name }}
      </option>
    </select>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref } from 'vue'
import { useApplyStore } from '@/store/apply'

export default defineComponent({
  name: 'FormItemSelectBox',
  props: {
    data: {},
    disabled: null
  },
  data () { return {} },
  disabled () { return false },
  setup (props) {
    const applyStore = useApplyStore()
    const selectedId = ref(null)
    const isDisabled = ref(false)

    const onChange = (event) => {
      const selectedOptionId = event.target.value
      applyStore.setAnswer(props.data.id, selectedOptionId)
    }

    onMounted(() => {
      if (props.data.answer != null) {
        selectedId.value = props.data.answer.formItemReply
      }

      if (props.disabled != null) isDisabled.value = props.disabled
    }
    )

    return {
      applyStore,
      selectedId,
      isDisabled,
      onChange
    }
  },
  components: {

  },
  methods: {

  }
})
</script>
