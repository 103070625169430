import { createRouter, createWebHistory } from 'vue-router'
import ApplyFormView from '@/views/bonapply/ApplyFormView'

const routes = [
  {
    path: '/apply-form/:eventFormToken/:iframeToken',
    name: 'apply-form',
    component: ApplyFormView
  },
  {
    path: '/apply-form/:eventFormToken/:applyUId',
    name: 'apply-form-detail',
    component: ApplyFormView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active',
  linkExactActiveClass: 'active'
})

export default router
