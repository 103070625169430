import { acceptHMRUpdate, defineStore } from 'pinia'
import CompanyService from '@/services/companyInfo.service'

export const useCompanyStore = defineStore({
  id: 'companyStore',
  state: () => ({
    company: localStorage.getItem('company')
  }),
  setup () {},
  actions: {
    getCompanyInfo () {
      return CompanyService.getCompanyInfo().then(
        response => {
          this.company = response.data.data.company
          localStorage.setItem('company', this.company)
          return response
        }
      )
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCompanyStore, import.meta.hot))
}
