import './styles/vakifbank-style.scss'
import './styles/bs5_theme.scss'
import './styles/app.scss'
import './styles/slider'

import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
import '@fortawesome/fontawesome-free/js/brands'

import { createPinia } from 'pinia'
import { createApp, markRaw } from 'vue'
import App from './App.vue'
import router from './router'
import VueClickAway from 'vue3-click-away'
import VueSnip from 'vue-snip'
import Toast from 'vue-toastification'
import FormItem from '@/components/bonapply/form/items/FormItem.vue'
import FormItemLabel from '@/components/bonapply/form/items/FormItemLabel.vue'
import BSModal from '@/components/bonapply/BSModal.vue'
import ApplyForm from '@/components/bonapply/ApplyForm.vue'
import VueCarousel from 'vue-carousel'

import 'vue-toastification/dist/index.css'
import 'bootstrap'

import { useConfigStore } from './store/config'
const app = createApp(App)

// app.config.compilerOptions.isCustomElement = tag => tag === 'form-item'

app.component('FormItem', FormItem)
app.component('FormItemLabel', FormItemLabel)
app.component('BSModal', BSModal)
app.component('ApplyForm', ApplyForm)

const pinia = createPinia()

pinia.use(({ store }) => {
  store.router = markRaw(router)
})

const toastOptions = {

}
app
  .use(router)
  .use(pinia)
  .use(Toast, toastOptions)
  .use(VueClickAway)
  .use(VueSnip)
  .use(FormItem)
  .use(VueCarousel)

const configStore = useConfigStore()
configStore.load().then((response) => {
  app.mount('#app')
})
