<template>
<div class="main-container">
    <default-header v-if="!isLanding()"></default-header>
    <main class="flex-shrink-1">
    <router-view/>
     </main>
    <DefaultFooter v-if="!isLanding()"/>
</div>
</template>
<script>
import { defineComponent, onMounted } from 'vue'
import { useAuthStore } from './store/auth'
import { useConfigStore } from './store/config'
import DefaultHeader from './components/bonapply/DefaultHeader'
import DefaultFooter from './components/bonapply/DefaultFooter.vue'
import { useRoute } from 'vue-router'
export default defineComponent({
  name: 'App',
  data () { return {} },
  setup () {
    const authStore = useAuthStore()
    const configStore = useConfigStore()
    const route = useRoute()
    configStore.load().then((response) => {
      // console.log('test', response)
    })
    onMounted(() => {
      document.title = 'Bonapply'
    })
    const isLanding = () => {
      if (route.name === 'index') return true
      return false
    }
    return {
      configStore,
      authStore,
      isLanding
    }
  },
  components: {
    DefaultHeader,
    DefaultFooter
  }
})
</script>
