<template>
  <div v-if="!isPageReady" class="text-center py-5">
    <div class="spinner-border text-info" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <h4>Form hazırlanıyor, lütfen bekleyin.</h4>
  </div>
  <div v-if="isPageReady">
    <div v-if="isShowForm" class="container">
      <div class="row">
        <div class="col-md-8 offset-md-2 text-center px-3">
          <h3 class="bg-light py-2">{{ applyStore.eventForm.title }}</h3>
        </div>
        <div class="col-md-8 offset-md-2 text-start px-5">
          <figure>
            <blockquote class="blockquote">
              <p>Başvuru sayfasında "Gönder"e basmadığınız sürece, cevaplarınız sistemde kayıtlı kalacak. Son başvuru tarihine kadar istediğiniz zaman tekrar bu forma girerek başvurunuzu düzenleyebilir ardından "Gönder" tuşu ile başvurunuzu tamamlayabilirsiniz.</p>
            </blockquote>
            <figcaption class="blockquote-footer mt-2">
              Başvuru formundaki bilgilerin detaylı ve net olması önemlidir. <cite title="Source Title">Teşekkürler!</cite>
            </figcaption>
          </figure>
          <p></p>
          <p></p>
        </div>
        <div class="col-md-8 offset-md-2 text-start p-4">
          <apply-form :applyFormUId="applyFormUId" @onApplySuccess="onApplySuccess"/>
        </div>
      </div>
    </div>
    <div v-if="applySuccess" id="applySuccess" class="container py-5">
      <div class="d-flex align-items-center justify-content-center">
        <div class="w-75">
          <div class="text-center">
            <i
              class="bi bi-check-circle teal"
              style="font-size: 55px"
            ></i>
          </div>
          <div class="text-center">
            <p class="font-size-2xl black">
              Başvurunuz başarıyla gönderildi.
            </p>
          </div>
          <div class="text-center">
            <p class="font-size-lg black">Tebrikler!</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useAuthStore } from '@/store/auth'
import { useApplyStore } from '@/store/apply'

export default defineComponent({
  name: 'ApplyFormView',
  data () { return {} },
  setup () {
    onMounted(() => {
      document.title = 'Bonapply | Başvuru'

      let iframeToken = '';
      if(route.params.iframeToken != null) iframeToken = route.params.iframeToken;

      authStore.login({
        'username': '',
        'password': ''
      },iframeToken).then(
        response => {
          if (response.status === 200) {
            init();
          }
        }
      )
    })
    const route = useRoute()
    const isPageReady = ref(false)
    const isShowForm = ref(false)
    const applySuccess = ref(false)
    const applyUId = route.params.applyUId
    const applyFormUId = ref(null)
    const applyStore = useApplyStore()
    const authStore = useAuthStore()
    const init = () => {
      if (applyUId === undefined) {
        applyStore.create().then(
          response => {
            if (response.status === 200) {
              applyFormUId.value = response.data.data.apply.uid
              isShowForm.value = true
              isPageReady.value = true
            }else{
              setTimeout(function() {
               history.back()
              }, 1500);
            }
          }
        )
      } else {
        applyFormUId.value = applyUId
      }
    }
    const onApplySuccess = () => {
      isShowForm.value = false
      applySuccess.value = true

      setTimeout(function() {
        document.getElementById("header").scrollIntoView()
      }, 200);

      //toast.success('Tebrikler, başvurunuz tamamlandı.')

      window.parent.postMessage({
        progress: 'toast',
        data: {
          type: 'success',
          message: 'Tebrikler, başvurunuz tamamlandı.'
        }
      },'*');

      window.parent.postMessage({
        progress: 'iframeHeight',
        height: 500
      },'*');
    }

    return {
      applyStore,
      applyFormUId,
      onApplySuccess,
      isShowForm,
      isPageReady,
      applySuccess,
    }
  },
  components: {},
  methods: {}
})
</script>
<style lang="scss" scoped>
@import 'src/styles/media_query.scss';
@import 'src/styles/bs5_theme.scss';
</style>
