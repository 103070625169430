<template>
  <div>
    <div>
      <label class="fw-bolder">Bir önceki aşamadaki başvurunuzu seçiniz</label>
      <button type="button"
              class="btn btn-sm p-0 float-end"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Başvurunuzu tamamlayabilmeniz için bir önceki aşamanın değerlendirmesinden geçmiş olan başvurunuzu seçmelisiniz. Yapacağınız seçim şu anki başvurunuz ile ilişkilendirilecektir.">
        <span class="fa fa-question-circle"></span>
      </button>
    </div>
    <select class="form-control" @change="onChange($event)" :disabled="isDisabled">
      <option disabled selected>Seçiniz</option>
      <option v-for="parentApply in data.parent_apply_list" :key="parentApply.id" :value="parentApply.id" :selected="parentApply.id==selectedId">
        {{ parentApply.title }}
      </option>
    </select>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref } from 'vue'
import { useApplyStore } from '@/store/apply'

export default defineComponent({
  name: 'FormItemParentApply',
  props: {
    data: {},
    disabled: null
  },
  data () { return {} },
  disabled () { return false },
  setup (props) {
    const applyStore = useApplyStore()
    const selectedId = ref(null)
    const isDisabled = ref(false)

    const onChange = (event) => {
      const selectedPatentApplyId = event.target.value
      applyStore.setApplyParentId(selectedPatentApplyId)
    }

    onMounted(() => {
      if (props.data.selected_parent_apply != null) {
        selectedId.value = props.data.selected_parent_apply.id
      }

      if (props.disabled != null) isDisabled.value = props.disabled
    })

    return {
      applyStore,
      selectedId,
      isDisabled,
      onChange
    }
  },
  components: {

  },
  methods: {

  }
})
</script>
