<template>
    <div>
        <h4>{{ data.title }}</h4>
    </div>
</template>
<script>
import { defineComponent, onMounted } from 'vue'

export default defineComponent({
  name: 'FormItemSubTitle',
  props: {
    data: {}
  },
  data () { return {} },
  setup (props) {
    onMounted(() => {

    }
    )

    return {

    }
  },
  components: {

  },
  methods: {

  }
})
</script>
