<template>
    <div>
        <form-item-label :data="data"/>
        <select class="form-select" @change="onChange($event)" :disabled="isDisabled">
            <option v-for="option in data.forms_items_options" :key="option.id" :value="option.id" :selected="option.id==selectedId">
                {{ option.title }}
            </option>
        </select>

        <div v-for="subItemData in subItems" :key="subItemData" class="mt-3">
            <form-item :data="subItemData.item" :disabled="isDisabled"/>
        </div>

    </div>
</template>
<script>
import { defineComponent, onMounted, ref } from 'vue'
import { useApplyStore } from '@/store/apply'

export default defineComponent({
  name: 'FormItemConditional',
  props: {
    data: {},
    disabled: null
  },
  data () { return {} },
  disabled () { return false },
  setup (props) {
    const applyStore = useApplyStore()
    const selectedId = ref(null)
    const isDisabled = ref(false)
    const subItems = ref(null)

    const onChange = (event) => {
      // subItemData.value = props.data
      const selectedOptionId = event.target.value
      subItems.value = props.data.forms_items_options[selectedOptionId].conditions
      applyStore.setAnswer(props.data.id, [selectedOptionId])
    }

    onMounted(() => {
      if (props.data.answer != null) {
        selectedId.value = props.data.answer.formItemReply
        const selectedOptionId = selectedId.value
        subItems.value = props.data.forms_items_options[selectedOptionId].conditions
      }

      if (props.disabled != null) isDisabled.value = props.disabled
    })

    return {
      applyStore,
      selectedId,
      isDisabled,
      onChange,
      subItems
    }
  },
  components: {

  },
  methods: {

  }
})
</script>
