<template>
<footer class="footer footer mt-auto">
    <!--
    <div class="container-fluid ">
        <div class="container py-3">
            <div class="row d-flex align-items-baseline">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-start down-sm-text-center">
                <p class="m-0 fw-light">© 2023 Türkiye Vakıflar Bankası T.A.O.</p>
            </div>
            </div>
        </div>
    </div>
    -->
</footer>
</template>
<style lang="scss" scoped>
@import 'src/styles/media_query.scss';
</style>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'App',
  data () { return {} },
  setup () {
    return {

    }
  },
  components: {

  }
})
</script>
