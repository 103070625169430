<template>
    <div class="modal fase" tabindex="-1" ref="modalRef"
        data-bs-backdrop="static" data-bs-keyboard="false"
    >
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
        <div class="modal-header border-0">
            <h5 class="modal-title"><slot name="title"></slot></h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <slot name="body"></slot>
        </div>
        <div class="modal-footer border-0">
            <slot name="footer"></slot>
        </div>
        </div>
    </div>
    </div>
</template>
<style lang="scss" scoped>
@import 'src/styles/bs5_theme.scss';
</style>

<script>
import { defineComponent, ref, defineExpose } from 'vue'
import { Modal } from 'bootstrap'

export default defineComponent({
  name: 'BSModal',
  props: {

  },
  data () { return {} },
  setup (props) {
    const modalRef = ref(null)
    let dialog = null

    const show = () => {
      if (dialog === null) dialog = new Modal(modalRef.value, { backdrop: true })
      dialog.show()
    }

    const close = () => {
      dialog.hide()
    }

    defineExpose({ show, close })

    return {
      modalRef,
      show,
      close
    }
  },
  components: {

  },
  methods: {

  }
})
</script>
