<template>
    <div>
        <form-item-label :data="data"/>
        <!--
        <template v-for="option in data.forms_items_options" :key="option.id">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" :id="'checkbox_'+option.id" :name="'checkbox_group_'+data.id" :value="option.id"  v-model="checkedIds" :disabled="isDisabled"/>
                <label class="form-check-label" :for="'checkbox_'+option.id"> {{ option.title }} </label>
            </div>
        </template>
        -->
        <br>
        <VueMultiselect
          v-model="checkedObjects"
          :options="options()"
          :placeholder="data.placeholderVal"
          :multiple="true"
          label="title"
          track-by="id"
          selectLabel="Eklemek için Enter'a bas"
          deselectLabel="Çıkartmak için Enter'a bas"
          selectedLabel="Seçildi"
          >
        </VueMultiselect>
    </div>
</template>
<script>
import { defineComponent, onMounted, ref, watch, toRaw } from 'vue'
import { useApplyStore } from '@/store/apply'
import VueMultiselect from 'vue-multiselect'

export default defineComponent({
  name: 'FormItemcheckbox',
  props: {
    data: {},
    disabled: null
  },
  data () { return {} },
  disabled () { return false },
  setup (props) {
    const applyStore = useApplyStore()
    const checkedIds = ref([])
    const checkedObjects = ref([])
    const isDisabled = ref(false)

    onMounted(() => {
      if (props.data.answer != null) {
        checkedIds.value = toRaw(props.data.answer.answer_option_ids)
        checkedObjects.value = initialOptions()
      }

      if (props.disabled != null) isDisabled.value = props.disabled
    }
    )

    const options = () => {
      return Array.from(Object.keys(props.data.forms_items_options), k => props.data.forms_items_options[k])
    }

    const initialOptions = () => {
      const tmp = []
      const initialOptionIds = toRaw(props.data.answer.answer_option_ids)
      options().forEach((item) => {
        if (initialOptionIds.includes(item.id)) tmp.push(item)
      })
      return tmp
    }

    watch(checkedObjects, (currentValue, oldValue) => {
      if (currentValue === oldValue) return
      checkedIds.value = checkedObjects.value.map(function (a) { return a.id })
      applyStore.setAnswer(props.data.id, toRaw(checkedIds.value))
    })

    return {
      applyStore,
      checkedIds,
      checkedObjects,
      isDisabled,
      options
    }
  },
  components: {
    VueMultiselect
  },
  methods: {

  }
})
</script>
