import { BaseService } from './BaseService'
import { useAuthStore } from '../store/auth'
import { useToast } from 'vue-toastification'

const toast = useToast()
const LOGIN_PATH = '/v1/login'
const REFRESH_TOKEN_PATH = '/token/refresh'
const SIGNUP_PATH = '/v1/register'
const PASSWORD_UPDATEABILITY_CHECK_PATH = '/v1/password/updateability-check'
const RESET_PASSWORD_PATH = '/v1/password/reset-request'
const RENEW_PASSWORD_PATH = '/v1/password/reset'
const CHANGE_PASSWORD_PATH = '/v1/password/change'
const ACTIVATION_PATH = '/v1/activation'

class AuthService extends BaseService {
  async renewToken (refreshToken) {
    if (refreshToken != null) {
      return this.post(REFRESH_TOKEN_PATH, { refreshToken: refreshToken }).then(response => {
        return response
      })
    } else {
      const authStore = useAuthStore()
      const redirectToUrl = localStorage.getItem('redirectToUrl')
      if (redirectToUrl === undefined || redirectToUrl === null) {
        const redirectToUrl = window.location.pathname
        localStorage.setItem('redirectToUrl', redirectToUrl)
      }

      //toast.error('Bu sayfaya erişebilmek için giriş yapmalısınız')

      window.parent.postMessage({
        progress: 'toast',
        data: {
          type: 'error',
          message: 'Bu sayfaya erişebilmek için giriş yapmalısınız.'
        }
      },'*');

      authStore.logout()
    }
  }

  signUp (formData) {
    return this.post(SIGNUP_PATH, formData)
  }

  login (formData,iframeToken) {
    let url = LOGIN_PATH;
    if(iframeToken !== '') url += '?iframeToken=' + iframeToken

    return this.post(url, formData).then(response => {
      return response
    })
  }

  activate (token) {
    return this.get(ACTIVATION_PATH + '/' + token)
  }

  passwordUpdateabilityCheck (formData) {
    return this.post(PASSWORD_UPDATEABILITY_CHECK_PATH, formData)
  }

  resetPassword (formData) {
    return this.post(RESET_PASSWORD_PATH, formData)
  }

  renewPassword (formData) {
    return this.put(RENEW_PASSWORD_PATH, formData)
  }

  changePassword (formData) {
    return this.post(CHANGE_PASSWORD_PATH, formData)
  }
}

export default new AuthService()
