<template v-if="applyId != null">
    <div :id="'snipped' + applyId" :style="style" v-html="text.replaceAll('\n','<br>')"></div>
    <div v-if="hasEllipsis && !isReadMoreOn">
      <span @click="onReadMoreClick(1)">Daha Fazla Göster<svg class="ps-1" style="height:11px;" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg></span>
    </div>
    <div v-if="hasEllipsis && isReadMoreOn">
      <span @click="onReadMoreClick(0)">Daha Az Göster<svg class="ps-1" style="height:11px;" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"/></svg></span>
    </div>
</template>

<script>
import { defineComponent, nextTick, onMounted, ref } from 'vue'

export default defineComponent({
  name: 'ReadMore',
  props: {
    text: null,
    applyId: null
  },
  text () { return '' },
  setup (props) {
    const hasEllipsis = ref(false)
    const isReadMoreOn = ref(false)
    const style = ref('max-height: max-content; overflow-y: hidden;')
    onMounted(() => {
      onSnipped()
    })
    nextTick().then(() => {
      setTimeout(function () {
        onSnipped()
      }, 500)
    })
    const onSnipped = (newState) => {
      if (document.getElementById('snipped' + props.applyId).clientHeight > 97) {
        hasEllipsis.value = true
        onReadMoreClick(0)
      }
    }
    const onReadMoreClick = (status) => {
      if (status === 1) {
        style.value = 'max-height: max-content; overflow-y: hidden;'
        isReadMoreOn.value = true
      } else {
        style.value = 'max-height: 97px; overflow-y: hidden;'
        isReadMoreOn.value = false
      }
    }

    return {
      onSnipped,
      hasEllipsis,
      onReadMoreClick,
      isReadMoreOn,
      style
    }
  },
  components: {

  },
  methods: {

  }
})
</script>
