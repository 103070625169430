<template>
    <div>
        <form-item-label :data="data"/>
        <input class="form-control" type="date" @change="onChange($event)" v-model="selectedDate" :disabled="isDisabled"/>
    </div>
</template>
<script>
import { defineComponent, onMounted, ref } from 'vue'
import { useApplyStore } from '@/store/apply'
import moment from 'moment'

export default defineComponent({
  name: 'FormItemDate',
  props: {
    data: {},
    disabled: null
  },
  data () { return {} },
  disabled () { return false },
  setup (props) {
    const applyStore = useApplyStore()
    const selectedDate = ref(null)
    const isDisabled = ref(false)

    const onChange = (event) => {
      const date = event.target.value
      applyStore.setAnswer(props.data.id, date)
    }

    onMounted(() => {
      if (props.data.answer != null) {
        selectedDate.value = moment(props.data.answer.formItemReply).format('YYYY-MM-DD')
      }

      if (props.disabled != null) isDisabled.value = props.disabled
    }
    )

    return {
      applyStore,
      onChange,
      isDisabled,
      selectedDate
    }
  },
  components: {

  },
  methods: {

  }
})
</script>
