<template>
    <div>
        <form-item-label :data="data"/>
        <input
            :key="fileInputClearKey"
            type="file"
            class="form-control d-none"
            @change=" (event) => { onChange(event); previewImage(event);}"
            ref="refFileInput"
            :disabled="isDisabled" />
    </div>
    <div class="border p-0 m-0 bg-light d-flex justify-content-center">
        <div @click="onPreviewClick" class="align-self-center p-1">

            <img v-if="preview" :src="preview" class="img img-fluid pointer"/>
            <div class="p-3">
                <h1 v-if="!preview && !fileNameString" class="bi bi-file-image"/>
                <h1 v-if="!preview && fileNameString" class="bi bi-file-earmark-check"/>
            </div>
        </div>
    </div>
    <a href='#' class="my-5">{{ fileNameString }}</a>
    <div class="text-center"  v-if="!isUploading">
        <button @click="onButtonClick" class="btn btn-secondary mt-2 button_4 rounded-10" style="font-size:14px!important;"> <span class="fa fa-plus me-2" style="font-size:14px!important;"></span> Dosya Yükle</button>
        <button @click="onRemoveButtonClick" class="btn"><span class="fa fa-trash"></span></button>
    </div>

    <div class="text-center"  v-if="isUploading">
    <span  class="fa fa-sm fa-spinner fa-spin"></span>
    </div>

</template>
<script>
import { defineComponent, onMounted, watch, ref } from 'vue'
import { useApplyStore } from '@/store/apply'

export default defineComponent({
  name: 'FormItemFile',
  props: {
    data: {},
    disabled: null
  },
  data () {
    return {}
  },
  disabled () { return false },
  setup (props) {
    const applyStore = useApplyStore()
    const inputValue = ref(null)
    const isDisabled = ref(false)
    const refFileInput = ref(null)

    const isUploading = ref(false)
    const fileName = ref('')
    const preview = ref('')
    const fileNameString = ref('')
    const image = ref({})
    const fileInputClearKey = ref(0)

    const previewImage = (event) => {
      const input = event.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = (e) => {
          const fileType = input.files[0].type
          if (fileType.search('image') >= 0) {
            preview.value = e.target.result
            fileNameString.value = null
          } else {
            preview.value = null
            fileNameString.value = input.files[0].name
          }
        }

        image.value = input.files[0]
        reader.readAsDataURL(input.files[0])
        // if (reader.readAsDataURL) { reader.readAsDataURL(input.files[0]) } else if (reader.readAsDataurl) { reader.readAsDataurl(input.files[0]) }

        /*
        uploadStore.upload(input.files[0]).then(
          result => {
            fileName.value = result.data.data.fileName
          }
        )
        */
      }
    }

    const onChange = (event) => {
      // const value = event.target.value
      const input = event.target
      if (input.files) {
        isUploading.value = true
        applyStore.upload(props.data.id, input.files[0]).then(
          response => {
            isUploading.value = false
            if (response.status === 200) {
              applyStore.setAnswer(response.data.data.form_item_id, response.data.data.form_item_value)
            } else {
              onRemoveButtonClick()
            }
          }
        )
      }
    }

    watch(() => props.data.answer, (first, seccond) => {
      if (props.data.answer !== undefined) preview.value = '/uploads' + props.data.answer
      else preview.value = null
    })

    onMounted(() => {
      /*
      if (props.data.answer != null) {
        inputValue.value = props.data.answer.formItemReply
      }
      */

      if (props.disabled != null) isDisabled.value = props.disabled

      fileName.value = props.data.answer

      if (props.data.answer !== undefined && props.data.answer.formItemReply !== '') {
        const filePath = props.data.answer.formItemReply
        if (/\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(filePath)) {
          preview.value = applyStore.links.file_show_base_url + filePath
          fileNameString.value = null
        } else {
          preview.value = null
          fileNameString.value = filePath.split('\\').pop().split('/').pop()
        }
      }
    })

    const onPreviewClick = () => {
      refFileInput.value.click()
    }

    const onButtonClick = () => {
      refFileInput.value.click()
    }

    const onRemoveButtonClick = () => {
      preview.value = null
      fileNameString.value = null
      fileInputClearKey.value++
      // applyStore.setAnswer(props.data.id, '')

      applyStore.upload(props.data.id, null).then(
        response => {
          applyStore.setAnswer(response.data.data.form_item_id, '')
        }
      )
    }

    return {
      applyStore,
      inputValue,
      isDisabled,
      onChange,
      onPreviewClick,
      onButtonClick,
      onRemoveButtonClick,
      refFileInput,
      previewImage,
      watch,
      preview,
      fileNameString,
      fileInputClearKey,
      isUploading
    }
  },
  components: {

  },
  methods: {

  }
})
</script>
<style lang="scss" scoped>
@import 'src/styles/media_query.scss';
@import 'src/styles/bs5_theme.scss';
@import 'src/styles/vakifbank-style.scss';
</style>
