<template>
  <button v-if="applyStore.isFeedProgram" class="btn btn-secondary button_4 font-size-base" style="height:37px !important;color:#000000 !important;background-color: #ffffff !important;" @click="onPreviewClick()" >Önizleme</button>
   <BSModal ref="refModal">
<template #header>
</template>
<template #body>
    <div class="d-flex align-items-center justify-content-center">
        <div v-if="applyStore.preview" class="w-100 m-lg-3">
            <div class="text-start">
              <p class="font-size-2xl black">
              Fikrin Önizlemesi
              </p>
            </div>
          <div class="wall-post mb-4">
            <div class="row">
              <div class="col-xl-7 col-lg-7 order-xl-1 order-lg-1 order-2 overflow-hidden word-break">
                <div class="mb-3 d-flex align-items-center">
                  <div>
                    <p class="m-0 me-2 dark-gray font-size-base fw-bold text-capitalize">{{ applyStore.preview.apply.user.name }} {{ applyStore.preview.apply.user.surname }}</p>
                  </div>
                  <div>
                    <p class="m-0 me-2 dark-gray font-size-base" v-if="applyStore.preview.apply.completeDate" >{{ applyStore.preview.apply.completeDate }}</p>
                    <p class="m-0 me-2 dark-gray font-size-base" v-if="!applyStore.preview.apply.completeDate">{{ applyStore.preview.apply.updateDate }}</p>
                  </div>
                </div>
                <div class="mb-3 cursor-pointer" v-if="applyStore.preview.answers.feed_program_apply_title">
                  <p class="m-0 black font-size-xxl fw-bold">{{applyStore.preview.answers.feed_program_apply_title.formItemReply}}</p>
                </div>
                <div class="mb-3" v-if="applyStore.preview.answers.feed_program_apply_description">
                  <p class="m-0 black font-size-base" style="word-break: break-word !important;">
                    <read-more :applyId="applyStore.preview.apply.id" :text="applyStore.preview.answers.feed_program_apply_description.formItemReply"/>
                  </p>
                </div>
                <div class="row mb-2">
                  <div class="col-xl-4 col-lg-6 col-12 pe-0" style="width:max-content !important;">
                    <p class="m-0 black font-size-base">Fayda Tipi:</p>
                  </div>
                  <div class="col-xl-4 col-lg-6 col-12" style="width:max-content !important;">
                    <div class="px-4 rounded-20 mb-2" style="border:solid 1px black">
                      <p class="m-0 black font-size-base">{{typeOfBenefit()}}</p>
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-baseline">
                  <div style="min-width: 60px;">
                    <p class="black pe-1 text-decoration-none font-size-base">Dosya:</p>
                  </div>
                  <div>
                    <p class="m-0 black font-size-base">
                      <a :href="applyFile()" target="_blank" class="text-black text-decoration-none">{{applyFileName()}}</a>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-xl-5 col-lg-5 order-xl-2 order-lg-2 order-1 cursor-pointer d-flex justify-content-end down_md_left">
                <img v-if="applyCoverImageFile()" :src="applyCoverImageFile()" alt="idea image" class="img-fluid w-100 down_992_margin_bottom rounded-10" style="max-height: 180px !important;width: max-content !important;"/>
              </div>
            </div>
          </div>
            <div class="d-flex">
              <button @click="onSendClick()" class="btn px-5 me-2 button_4">Gönder</button>
              <button @click="onCancelClick()" class="btn button_5 px-5">Vazgeç</button>
            </div>
        </div>
    </div>
</template>
<template #footer>
</template>
  </BSModal>
</template>
<script>
import { defineComponent, ref, onMounted } from 'vue'
import { useApplyStore } from '../../store/apply'
import ReadMore from "@/components/bonapply/ReadMore.vue";
import {useConfigStore} from "@/store/config";

export default defineComponent({
  name: 'ApplyPreview',
  props: {
    applyId: null
  },
  data () { return {} },
  setup (props, context) {
    const refModal = ref(null)
    const applyStore = useApplyStore()
    const configStore = useConfigStore()

    onMounted(() => {

    })

    const onPreviewClick = () => {
      refModal.value.show()
      applyStore.getSingle(props.applyId)
    }

    const onCancelClick = () => {
      refModal.value.close()
    }

    const onSendClick = (event) => {
      refModal.value.close()
      context.emit('onSendClick', {})
    }

    const typeOfBenefit = () => {
      if (applyStore.preview.answers.feed_program_apply_types_of_benefit) return applyStore.preview.answers.feed_program_apply_types_of_benefit.applyAnswerOptionsString
      else return '-'
    }

    const applyFile = () => {
      if (applyStore.preview.answers.feed_program_apply_file) { return configStore.downloadBasePath() + applyStore.preview.answers.feed_program_apply_file.formItemReply } else return false
    }

    const applyFileName = () => {
      if (applyFile()) {
        if (applyStore.preview.answers.feed_program_apply_file) { return applyStore.preview.answers.feed_program_apply_file.applyAnswerFileName } else return ''
      } else {
        return ''
      }
    }

    const applyCoverImageFile = () => {
      if (applyStore.preview.answers.feed_program_apply_cover_image_file) {
        return configStore.imageBasePath() + applyStore.preview.answers.feed_program_apply_cover_image_file.formItemReply
      } else {
        return false
      }
    }

    return {
      refModal,
      onPreviewClick,
      applyStore,
      onCancelClick,
      onSendClick,
      typeOfBenefit,
      applyFile,
      applyFileName,
      applyCoverImageFile,
    }
  },
  components: {
    ReadMore,
  }
})
</script>
<style lang="scss" scoped>
@import "src/styles/media_query.scss";
@import 'src/styles/vakifbank-style.scss';
</style>
