<template>
    <div>
        <form-item-label :data="data"/>
        <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
          <template v-for="option in data.forms_items_options" :key="option.id">
            <input type="radio"
                   autocomplete="off"
                    class="btn-check"
                    :id="'yes_no_'+option.id"
                    :name="'yes_no_'+data.id"
                    :value="option.id"
                    @change="onChange($event)"
                    :checked="option.id==selectedId"
                    :disabled="isDisabled"
                    style="height:37px!important;"
                    />
            <label class="btn btn-outline-secondary btn-sm ps-3 pe-3 d-flex align-items-center justify-content-center" style="height:37px!important;"  :for="'yes_no_'+option.id">{{ option.title }}</label>
          </template>
        </div>
    </div>
</template>
<script>
import { defineComponent, onMounted, ref } from 'vue'
import { useApplyStore } from '@/store/apply'

export default defineComponent({
  name: 'FormItemYesNo',
  props: {
    data: {},
    disabled: null
  },
  data () { return {} },
  disabled () { return false },
  setup (props) {
    const applyStore = useApplyStore()
    const selectedId = ref(null)
    const isDisabled = ref(false)

    const onChange = (event) => {
      const selectedOptionId = event.target.value
      applyStore.setAnswer(props.data.id, [selectedOptionId])
    }

    onMounted(() => {
      if (props.data.answer != null) {
        selectedId.value = props.data.answer.formItemReply
      }
      if (props.disabled != null) isDisabled.value = props.disabled
    }
    )

    return {
      applyStore,
      selectedId,
      isDisabled,
      onChange
    }
  },
  components: {

  },
  methods: {

  }
})
</script>
