<template>
  <header id="header">
    <!--
    <div class="container">
      <div class="row">
        <nav class="navbar navbar-expand-lg navbar-light py-5">
          <div class="container p-0">
            <a href="/">
              <img v-if="companyStore.company" src="@/assets/LeadImages/landing-img9.svg" class="img-fluid" :alt="companyStore.company.title" width="140">
            </a>
          </div>
        </nav>
      </div>
    </div>
    -->
  </header>
</template>
<script>
import { defineComponent } from 'vue'
import { useCompanyStore } from '@/store/company'

export default defineComponent({
  name: 'LoginView',
  data () { return {} },
  setup () {
    const companyStore = useCompanyStore()
    companyStore.getCompanyInfo()

    return {
      companyStore,
    }
  },
  components: {},
  methods: {

  }
})
</script>
<style lang="scss" scoped>
.navbar-toggler:focus{box-shadow: none;}
@import 'src/styles/media_query.scss';
.dropdown-item:hover{
  border-radius:10px !important;
}
</style>
